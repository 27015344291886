import React from "react"
import classNames from "classnames"

import styles from "./utils/layout.module.scss"

const Disclaimer = ({ color, header, message, isActive, closeDisclaimer }) => {
  return (
    <div
      className={classNames("notification", styles["disclaimer"], {
        "has-background-grey-lighter": !color,
        "is-hidden": !isActive,
      })}
    >
      <div className="content is-small has-text-left mb-0">
        <h2 className="mt-0">{header}</h2>
        {message}
      </div>
      <button className="delete" onClick={closeDisclaimer} />
    </div>
  )
}

export default Disclaimer
