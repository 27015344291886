export const isObjectEmpty = (object) => {
  for (var i in object) return false
  return true
}

export const isBrowser = () => typeof window !== "undefined"

export const convertToBoolean = ({ data }) => {
  let tempObject = {}
  Object.keys(data).forEach((key) => {
    switch (data[key]) {
      case "Yes":
        tempObject[key] = true
        break
      case "No":
        tempObject[key] = false
        break
      default:
        tempObject[key] = data[key]
    }
  })

  return tempObject
}

export const convertBooleanToString = ({ data }) => {
  let tempObject = {}
  Object.keys(data).forEach((key) => {
    switch (data[key]) {
      case true:
        tempObject[key] = "Yes"
        break
      case false:
        tempObject[key] = "No"
        break
      default:
        tempObject[key] = data[key]
    }
  })

  return tempObject
}
