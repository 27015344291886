import React, { useReducer } from "react"
import { AppReducer } from "./AppReducer"
import Modal from "../components/Elements/Modal"
import Toast from "../components/Layout/Toast"
import Disclaimer from "../components/Layout/Disclaimer"

export const initialState = {
  documents: [],
  feedback: {},
  enrollment: {
    id: {
      front: "",
      back: "",
    },
    agreeToConsent: [],
    firstName: "",
    lastName: "",
    birthday: {
      month: { label: "", value: "" },
      date: { label: "", value: "" },
      year: "",
    },
    gender: "",
    mobileNumber: "",
    employeeNumber: "",
    site: { label: "", value: "" },
    department: "",
    employmentStart: "",
    address: {
      addressType: "Home",
      streetAddress: "",
      province: "",
      city: "",
    },
    emergencyContact: {
      name: "",
      mobileNumber: "",
      relationship: { label: "", value: "" },
    },
  },
  auth: {
    email: "",
    password: "",
    confirmPassword: "",
    hasBeenVerified: false,
  },
  selfRequest: {
    specificProblemIssue: "",
    causeOfDistress: "",
    hasConsultedWithPsychInProgram: "",
    hasConsultedWithPsychOutsideProgram: "",
    hasBeenHospitalized: "",
    isTakingPsychMeds: "",
    isTakingNonPsychMeds: "",
    hasDrugAllergies: "",
    hasBeenDiagnosed: "",
    specificProblemIssueDetails: "",
    previousPsychName: "",
    psychMedsDetails: "",
    nonPsychMedsDetails: "",
    drugAllergiesDetails: "",
    mentalIllnessDiagnosis: "",
    kesslerNervous: "",
    kesslerHopeless: "",
    kesslerRestless: "",
    kesslerDepressed: "",
    kesslerEffort: "",
    kesslerWorthless: "",
    hasWishedToBeDead: "",
    firstChoiceSchedule: { day: "", time: "" },
    secondChoiceSchedule: { day: "", time: "" },
    thirdChoiceSchedule: { day: "", time: "" },
    hasUsedGoogleMeet: [],
    otherNotes: "",
    cssrs: {
      hadSuicidalThoughts: "",
      hasThoughtSuicidalMethod: "",
      hadSuicidalIntent: "",
      hadSuicidalIntentWithPlan: "",
      hasActedOnSuicidalThoughts: "",
    },
  },
  refer: {
    // agreeToConsent: [],
    referrer: {
      firstName: "",
      lastName: "",
      birthday: {
        month: "",
        date: "",
        year: "",
      },
      mobileNumber: "",
      email: "",
      currentRole: [],
    },
    employee: {
      firstName: "",
      lastName: "",
      gender: "",
      birthday: {
        month: "",
        date: "",
        year: "",
      },
      site: "",
      department: "",
      mobileNumber: "",
      employeeNumber: "",
      email: "",
      // personalEmail: "",
      currentWorkHours: "",
    },
    reasonForReferral: "",
    riskAssessmentScore: "",
  },
  requestComplete: false,
  abstract: {
    zendeskTicketId: "",
    mhpName: "",
    profession: "",
    patientName: "",
    reasonForSeekingMHSupport: "",
    diagnosis: "",
  },
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    background: {
      color: null,
      opacity: "100",
    },
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  disclaimer: {
    isActive: false,
    header: null,
    message: null,
    color: null,
  },
  user: {
    email: "",
    password: "",
  },
}

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Modal
        isModalActive={state.modal.isActive}
        isFullheight={state.modal.isFullheight}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state.modal.heading}
        modalHeaderClass={state.modal.headerClass}
        modalBackground={state.modal.background}
        hideCloseButton={state.modal.hideCloseButton}
        isCard={state.modal.isCard}
      >
        {state.modal.content}
      </Modal>
      <Toast
        isActive={state.toast.isActive}
        message={state.toast.message}
        color={state.toast.color}
      />
      {children}
      <Disclaimer
        isActive={state.disclaimer.isActive}
        message={state.disclaimer.message}
        header={state.disclaimer.header}
        color={state.disclaimer.color}
        closeDisclaimer={() => dispatch({ type: "HIDE_DISCLAIMER" })}
      />
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
