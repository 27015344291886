import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/services/apollo"

import { getFirebase } from "services/firebase/firebase"
import { AppProvider } from "./src/context/AppContext"
import "./static/styles/global.scss"

export const wrapRootElement = ({ element }) => {
  getFirebase()
  return (
    <AppProvider>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </AppProvider>
  )
}
