import { isBrowser } from "../general"
import firebase from "firebase/app"
import "firebase/auth"

import {
  GATSBY_FIREBASE_APP_ID,
  GATSBY_FIREBASE_API_KEY,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_AUTH_DOMAIN,
  GATSBY_FIREBASE_DATABASE_URL,
  GATSBY_FIREBASE_STORAGE_BUCKET,
  GATSBY_FIREBASE_MESSAGING_SENDER_ID,
} from "gatsby-env-variables"

const FIREBASE_CONFIG = {
  apiKey: GATSBY_FIREBASE_API_KEY,
  authDomain: GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: GATSBY_FIREBASE_DATABASE_URL,
  projectId: GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: GATSBY_FIREBASE_APP_ID,
}

let instance = null

export const getFirebase = () => {
  if (isBrowser()) {
    if (instance) return instance

    instance = firebase.initializeApp(FIREBASE_CONFIG)
    return instance
  }

  return null
}
